<template>
  <div>
    <heads></heads>
    <div class="cen" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="cen_box">
        <div class="cen-com">
          <div v-for="(item, index) in traditionCourseList" :key="index">
            <router-link :to="{
              path: '/tradition/detail',
              query: {
                id: item.id,
              },
            }" class="items">{{ item.name }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getTraditionCourseList } from "@api/public";
export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      traditionCourseList: [],
      backgroud: "",
    };
  },
  mounted: function () {
    let that = this;
    getTraditionCourseList().then(function (res) {
      that.$set(that, "traditionCourseList", res.data.list);
    });
  },
  created: function () {
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
};
</script>
<style scoped>
.cen {
  width: 100%;
  height: 1083px;
  min-width: 1484px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.cen_box {
  margin: 0 auto;
  width: 1484px;
  height: 1083px;
  position: relative;
}

.cen-com {
  width: 500px;
  height: 400px;
  position: absolute;
  top: 240px;
  left: 510px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.items {
  width: 17px;
  color: #000;
  text-decoration: none;
  display: block;
  font-size: 22px;
  margin: 0 20px;
  font-family: "楷体";
  font-weight: bold;
  writing-mode: vertical-lr;
}

.items:hover {
  color: #e2cea0;
}
</style>